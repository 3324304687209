import { format } from 'date-fns';

export function formatDateTimeView(date: string | undefined): string | undefined {
  return date ? format(new Date(date), 'dd-MM-yyyy HH:mm') : undefined;
}

export function formatDateView(date: string | undefined): string | undefined {
  return date ? format(new Date(date), 'dd-MM-yyyy') : undefined;
}

export function formatTimeView(date: string | undefined): string | undefined {
  return date ? format(new Date(date), 'HH:mm') : undefined;
}

export function formatDatePicker(date: string | undefined): string | undefined {
  return date ? new Date(date).toISOString().substr(0, 10) : undefined;
}
