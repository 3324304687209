export const PROCESS_TYPES_SELECT = [
  { text: 'P_121', value: 0 },
  { text: 'P_134', value: 1 },
  { text: 'VACUUM', value: 2 },
  { text: 'HELIX', value: 3 },
  { text: 'FAST', value: 4 },
  { text: 'PRION', value: 5 },
  { text: 'IDLE', value: 99 },
];

export const PROCESS_TYPES_MAP = {
  P_121: 0,
  P_134: 1,
  VACUUM: 2,
  HELIX: 3,
  FAST: 4,
  PRION: 5,
  IDLE: 99,
};

export const PROCESS_TYPES_VALUE_MAP = {
  0: 'P_121',
  1: 'P_134',
  2: 'VACUUM',
  3: 'HELIX',
  4: 'FAST',
  5: 'PRION',
  99: 'IDLE',
};

export const PROCESS_TYPES_VALUE_MAP_VIEW = {
  0: '121',
  1: '134',
  2: 'Vacuum',
  3: 'Helix',
  4: 'Fast',
  5: 'Prion',
  99: 'Idle',
};
