export function getTemperatureUnit(metricUnits: boolean) {
  if (metricUnits) {
    return '°C';
  } else {
    return '°F';
  }
}

export function getPressureUnit(metricUnits: boolean) {
  if (metricUnits) {
    return 'bar';
  } else {
    return 'psi';
  }
}

export function addBrackets(text: string) {
  return `[${text}]`;
}
